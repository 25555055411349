<template>
  <b-card title="Term Condition List">
    <b-col cols="12">
      <!-- <b-row class="mb-2">
        <b-col cols="4">
          <b-button v-if="permission.add" @click="toAddTermCondition" variant="success">
            Add Term Condition
          </b-button>
        </b-col>
        <b-col cols="4"/>
        <b-col cols="4">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row> -->
      <b-row>
        <b-col cols="12">
          <b-table
            :fields="fields"
            :filter="search"
            :current-page="currentPage"
            :per-page="perPage"
            :items="TermCondition"
            show-empty
          >
            <template #cell(updatedAt)="row">
              {{row.item.updatedAt | formatDate}}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="permission.edit"
                >
                  <b-button
                    variant="outline-primary"
                    @click="toEditTermCondition(row.item._id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="permission.delete"
                >
                  <b-button variant="danger" @click="doDeleteTermCondition(row.item._id)">
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
        <!-- <b-col cols="12">
          <b-pagination
            :total-rows="TermCondition.length"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
          />
        </b-col> -->
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions,mapState } from "vuex";
import { userAccess } from "@/utils/utils";
import { dateFormat } from "@/utils/utils";

export default {
  data() {
    return {
      fields: [
        { key: "title", label: "Name", filterable: true, sortable: true },
        { key: "module", label: "Module", filterable: true, sortable: true },
        { key: "subModule", label: "Sub Module", filterable: true, sortable: true },
        { key: "updatedBy", label: "Last Updated", filterable: true, sortable: true },
        { key: "updatedAt", label: "Updated", filterable: true, sortable: true },
        { key: "actions", label: "Action" },
      ],
      TermConditionData: [],
      search: "",
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.$store.dispatch("TermCondition/fetchTermCondition");
  },
  methods: {
    dates(date) {
      return dateFormat(date);
    },
    ...mapActions({
    }),
    toAddTermCondition() {
      this.$router.push("/warranty/TermsCondition-add");
    },
    toEditTermCondition(id) {
      this.$router.push(`/warranty/TermsCondition-edit/${id}`);
    },
  },
  computed: {
    ...mapState({
      TermCondition: (state) => state.TermCondition.TermCondition,
    }),
    permission() {
      console.log("userAccess", userAccess("Terms Condition", "warranty_master_menu"));
      return userAccess("Terms Condition", "warranty_master_menu");
    },
    TermCondition() {
      return this.$store.getters["TermCondition/getTermCondition"];
    },
  },
  created() {
    document.title = "Term Condition Project Warranty | RSP";
  },
};
</script>
